

































import {Component, Prop} from 'vue-property-decorator';
import AppComponent from '@/mixins/ComponentMixin.vue';
import PatientNotes from '@/components/PatientNotes.vue';
import {
  dispatchCreatePatientCustomerService,
  dispatchGetCustomerServiceByPatientId,
  dispatchGetPatientNotes,
  dispatchGetUsersForList,
  dispatchUpdatePatientCustomerService,
} from '@/store/crud/actions';

@Component({components: {PatientNotes}})
export default class PatientCustomerService extends AppComponent {
  @Prop({required: true}) public patientId: string | undefined;
  public assignedUser: string = '';
  public fetching = false;
  public patientNotes: any = [];
  public customerService: any = null;
  public isEdit = false;

  public onUserChange() {
    if (this.assignedUser) {
      this.submit();
    }
  }

  public async submit() {
    let result: any = null;
    if (this.isEdit) {
      result = await dispatchUpdatePatientCustomerService(this.$store, {
        id: this.customerService.id,
        assignedUserId: this.assignedUser,
      });
    } else if (this.patientId) {
      result = await dispatchCreatePatientCustomerService(this.$store, {
        patientId: this.patientId,
        userId: this.assignedUser,
      });
    }

    if (result) {
      this.assignedUser = result.assignedUser.id;
    } else {
      this.assignedUser = this.customerService?.assignedUser?.id ?? '';
    }
  }

  public get showPatientNotes() {
    return !!this.patientNotes.length;
  }

  public mounted() {
    this.fetchData();
  }

  public reset() {
    if (this.customerService) {
      this.assignedUser = this.customerService.assignedUser.id;
      this.isEdit = true;
    } else {
      this.assignedUser = '';
    }
  }

  public handleCreateNote(newNote: any) {
    this.patientNotes.push(newNote);
  }

  public async fetchData() {
    this.fetching = true;
    await dispatchGetUsersForList(this.$store);
    if (this.patientId) {
      this.patientNotes = await dispatchGetPatientNotes(this.$store, this.patientId);
      this.customerService = await dispatchGetCustomerServiceByPatientId(this.$store, this.patientId);
    }
    await this.$nextTick();
    this.fetching = false;
    this.reset();
  }
}
