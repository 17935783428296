

























import {Component, Vue, Prop, Watch} from 'vue-property-decorator';

/**
 * @class CreateEditCommentModal - a modal that allows the user
 * to edit or create a comment
 */
@Component
export default class CreateEditCommentModal extends Vue {
  @Prop({type: String, default: ''}) public comment: any;
  @Prop({type: String, default: ''}) public id: any;
  @Prop({type: String, default: 'Edit'}) public triggerLabel: string;
  @Prop({type: String, default: 'edit'}) public triggerIcon: string;
  public updatedComment = '';
  public dialog = false;

  @Watch('comment', {immediate: true})
  public onCommentChanged(newComment: string) {
    this.updatedComment = newComment;
  }

  /**
   * saves the changes to the current comment by emitting an event
   */
  public async saveComment() {
    if (this.updatedComment && this.updatedComment.length > 0) {
      await this.$nextTick();
      this.$emit('input', {
        comment: this.updatedComment,
        id: this.id,
      });
    }
  }
}
