













import {Component, Prop} from 'vue-property-decorator';
import PatientNote from '@/components/PatientNote.vue';

import AppComponent from '@/mixins/ComponentMixin.vue';
import {IPatientNote} from '@/interfaces/customerService';
import CreateEditCommentModal from './modals/CreateEditCommentModal.vue';
import {dispatchCreatePatientNote} from '@/store/crud/actions';

@Component({components: {PatientNote, CreateEditCommentModal}})
export default class PatientNotes extends AppComponent {
  @Prop({required: true, type: Array}) public patientNotes: IPatientNote[];
  @Prop({required: true, type: String}) public patientId: string;
  @Prop({required: false, type: Boolean, default: true}) public canCreate: boolean;

  public savedComment = '';

  public async createPatientNote(creationPayload: {id: string; comment: string}) {
    this.savedComment = creationPayload.comment;
    const result = await dispatchCreatePatientNote(this.$store, {
      patientId: this.patientId,
      text: creationPayload.comment,
    });

    // UX friendly comment temp save if the comment fails to save
    if (result) {
      this.savedComment = '';
      // emit the note creation to the parent component
      this.$emit('note-created', result);
    }
  }
}
