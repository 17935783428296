




















import {IPatientNote} from '@/interfaces/customerService';
import CreateEditCommentModal from './modals/CreateEditCommentModal.vue';
import {Component, Vue, Prop} from 'vue-property-decorator';
import {dispatchUpdatePatientNote} from '@/store/crud/actions';

@Component({components: {CreateEditCommentModal}})
export default class PatientNote extends Vue {
  @Prop({type: Object}) public note: IPatientNote;

  public get noteText() {
    return this.note?.text ?? '';
  }

  public get noteCreator() {
    if (this.note.noteCreator) {
      return `${this.note?.noteCreator?.fname ?? ''} ${this.note?.noteCreator?.lname ?? ''}`.trim();
    }
  }

  public get creationDate() {
    // get the date in local time from utc
    return new Date(this.note?.createdAt ?? '').toLocaleString();
  }

  public get noteCreatorShortName() {
    if (this.note.noteCreator) {
      return `${this.note?.noteCreator?.fname?.charAt(0) ??
        ''}${this.note?.noteCreator?.lname?.charAt(0) ?? ''}`
        .trim()
        .toUpperCase();
    }
  }

  public async updateNote(updatePayload: {id: string; comment: string}) {
    const result = await dispatchUpdatePatientNote(this.$store, {
      id: this.note.id,
      note: {text: updatePayload.comment},
    });

    if (result) {
      this.note.text = updatePayload.comment;
    }
  }
}
